import React from 'react';
import {
  FaLinkedinIn,
  FaBehance,
  FaGithub,
  FaTwitter,
  FaEnvelope,
} from 'react-icons/fa';
import { SiPolywork } from 'react-icons/si';

export default (props) => (
  <footer className="site-footer">
    <p className="mt-1">
      &copy;{new Date().getFullYear()}{' '}
      <a
        href="https://peerlist.io/anubhavbagri"
        target="_blank"
        rel="noopener noreferrer"
      >
        Portfolio
      </a>
      , by{' '}
      <a
        href="mailto:anubhavbagri01@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Anubhav Bagri
      </a>
      .
    </p>
    {/* <a
      href="https://poly.work/anubhavbagri"
      target="_blank"
      rel="noopener noreferrer"
      className="mr-3"
    >
      <FaEnvelope size="1.3em" />
    </a> */}
    <a
      href="https://twitter.com/oyebagri"
      target="_blank"
      rel="noopener noreferrer"
      className="mr-3"
    >
      <FaTwitter size="1.3em" />
    </a>
    <a
      href="https://linkedin.com/in/anubhavbagri"
      target="_blank"
      rel="noopener noreferrer"
      className="mr-3"
    >
      <FaLinkedinIn size="1.3em" />
    </a>
    <a
      href="https://github.com/anubhavbagri"
      target="_blank"
      rel="noopener noreferrer"
      className="mr-3"
    >
      <FaGithub size="1.4em" />
    </a>
    <a
      href="https://behance.net/anubhavbagri"
      target="_blank"
      rel="noopener noreferrer"
      className="mr-3"
    >
      <FaBehance size="1.4em" />
    </a>
    <a
      href="https://poly.work/anubhavbagri"
      target="_blank"
      rel="noopener noreferrer"
      // className="mr-3"
    >
      <SiPolywork size="1.2em" />
    </a>
  </footer>
);
